import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition';

import { MetaTags, Header, Footer, Newsletter, Screensaver } from '../'
import { media } from '../../styles/utils';

import 'swiper/css/swiper.css'
import 'keen-slider/keen-slider.min.css'

const Layout = (props) => {
	const { children, meta, background, headerFloating, breadcrumb, breadcrumbLink, breadcrumbDisableH1, isHome } = props;
	
	return (
		<>	
			<MetaTags
				{...meta}
			/>

			<Header
				breadcrumb={breadcrumb}
				breadcrumbLink={breadcrumbLink}
				breadcrumbDisableH1={breadcrumbDisableH1}
				background={background}
				floating={headerFloating}
				isHome={isHome}
			/>

			<Newsletter/>
			{/* <Screensaver/> */}

			<Wrapper
				background={background}
			>
				{/* <Transition> */}
					{children}
				{/* </Transition> */}
			</Wrapper>

			<Footer/>
		</>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: calc(100vh - 363px);
	box-sizing: border-box;

	/* Background */

	${props => {
		if (props.background) return css`
			background: ${props.background};
		`
	}}
`

export default Layout
