import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import Form, { useForm } from 'react-dynamic-form'
import styled, { css } from 'styled-components'
import { useMount, useIdle } from 'react-use';

import { Field } from '..'

import { media } from '../../styles/utils'
import { padding, type, textHover, colours } from '../../styles/global'
import { getGlobalData } from '../../utils'

const Screensaver = (props) => {
	const { data } = getGlobalData('screensaver')
	const [active, setActive] = useState(false)	
	const isIdle = useIdle(20000);

	useEffect(() => {
		if (isIdle && !active) setActive(true)
		if (!isIdle && active) setActive(false)
	}, [isIdle])

	return (
		<Wrapper
			active={active}
		>
			<Heading
				dangerouslySetInnerHTML={{__html: data.heading}}
			/>

			<Text
				dangerouslySetInnerHTML={{__html: data.text}}
			/>
			
			<Subheading
				dangerouslySetInnerHTML={{__html: data.credit}}
			/>
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``

// Layout

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	background: rgba(156, 66, 31, 0.98);
	padding: 40px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;

	${media.phone`
		padding: 20px 40px;
	`}

	${Text} {
		max-width: 860px;

		p:last-child {
			margin-bottom: 0;
		}
	}

	${Subheading} {
		${type.caption}
	}

	/* Active */

	transition: opacity 1.5s ease;
	opacity: 0;
	will-change: opacity;
	pointer-events: none;
	
	${props => {
		if (props.active) return css`
			opacity: 1;
			pointer-events: all;
		`
	}}
`



export default Screensaver
