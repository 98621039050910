import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { type, colours } from '../../../styles/global'
import { renderError, inputBase } from '../shared'

const FieldInput = (props) => {
	const [focused, setFocused] = useState(false)

	const getInputProps = () => {
		return {
			onChange: (e) => props.onChange(e.target?.value),
			onFocus: (e) => setFocused(true),
			onBlur: (e) => setFocused(false),
		}
	}

	return (
		<Wrapper>
			{props.label && (
				<Heading
					className={'field-label'}
				>
					{props.label}
				</Heading>
			)}
			
			<Input 
				{...props}
				{...getInputProps()}
			/>

            {/*{renderError(props, focused)}*/}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``

// Layout

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	${media.phone`
		min-width: 284px;
	`}
	
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button {  
		opacity: 1;
		-webkit-appearance: none; 
	}
`

// Input

export const inputStyle = css`
   	${inputBase}
   	transition: all 0.25s ease;
	font-family: 'Vremena', sans-serif;
	background: none;

    &, &::placeholder {
	    ${type.body};
		color: black;
    }

    &:focus,
    &:hover {
        outline: none;
    }

	&:focus::placeholder {
		color: transparent;
	}

    ${props => {
        if (props.shouldValidate && !props.valid) return css`
			
			&, &::placeholder {
				color: ${colours.red}
			}
        `
	}}

`

const Input = styled.input`
    ${inputStyle}
`

FieldInput.wrapper = css``
FieldInput.displayName = 'FieldInput'

export default FieldInput
