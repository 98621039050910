import React, { useState, useEffect } from 'react'// import { navigate, Link, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'
import { useMount } from 'react-use';
import { useKeenSlider } from "keen-slider/react"

import { media, isClient, useBreakpoint } from '../../styles/utils'
import { container, bgImage, padding, hoverState, type, colours, bgIcon } from '../../styles/global'

const Slider = (props) => {
	const {slides, sliderStyles, hoverCaption} = props;
	const canLoop = slides && slides.length > 1;
	const [hovering, setHovering] = useState(false)
	const [opacities, setOpacities] = React.useState([])
	const [mounted, setMounted] = useState(false)
	const isPhone = useBreakpoint('phone')

	useMount(() => {
		isClient() && setMounted(true)
	})

	const [sliderRef, slider] = useKeenSlider({
		slides: slides.length,
		loop: canLoop,
		duration: 0,
		move(s) {
			const new_opacities = s.details().positions.map((slide) => slide.portion)
			setOpacities(new_opacities)
		},
	})

	useEffect(() => {
		if (sliderRef) props.onSliderMount && props.onSliderMount(sliderRef)
	}, [sliderRef])

	const renderSlide = (item, i) => {
		const image = item.image;

		return (
			<Slide
				key={i}
				hoverCaption={hoverCaption}
				hovering={hovering}
				className={['keen-slider__slide', 'slide']}
				style={{ 
					opacity: opacities[i] 
				}}
			>
				<ImageWrapper
					className={'image-wrapper'}
					mounted={mounted}
				>	
					<Img 
						className={'image'}
						src={image?.sizes?.medium2} 
						alt={image?.alt}
					/>

					{item.use_caption && renderCaption(item.caption)}
				</ImageWrapper>
			</Slide>
		)
	}

	const renderCaption = (caption) => {
		if (!caption) return; 

		return (
			<Caption
				className={'caption'}
				dangerouslySetInnerHTML={{
					__html: caption,
				}}
			/>
		)
	}

	const renderSlides = () => {
		if (!slides || !slides.length) return;

		return slides.map((item, i) => {
			return renderSlide(item, i)
		})
	}

	const renderHoverCursors = () => {
		return (
			<HoverCursors 
				className="hover-cursors"
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}
				onClick={() => {
					isPhone && slider.next()
				}}
			>
				<Hover
					position={'left'}
					onClick={() => {
						slider.prev()
					}}
				/>
				
				<Hover
					position={'right'}
					onClick={() => {
						slider.next()
					}}
				/>
			</HoverCursors>
		)
    }

	return (
		<Wrapper
			mounted={mounted}
		>
			<SliderWrapper
				ref={sliderRef} 
				sliderStyles={sliderStyles}
			>
				{props.renderSlides && props.renderSlides(slides) || renderSlides()}
			</SliderWrapper>

			{props.hoverCursors && canLoop && renderHoverCursors()}
		</Wrapper>
	)
}

// Shared

const Description = styled.div``
const Subheading = styled.div``
const Icon = styled.div``

const Img = styled.img`
	overflow: hidden;
`

// Layout

const defaultSliderStyles = css`
	position: relative;
	width: 100%;
`

const Wrapper = styled.div`
	position: relative;
	opacity: 0;
	transition: opacity 0.45s ease;

	/* Keen slider SSR issue */

	${props => {
		if (props.mounted) return css`
			opacity: 1;
		`
	}}
`

const SliderWrapper = styled.div`
	display: flex;
  	position: relative;
	${props => props.sliderStyles || defaultSliderStyles}

	.keen-slider__slide { 
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
	}	
`

// Slide

const Caption = styled.div`
	margin-top: 16px;
	
	&, p {
		${type.caption}
	}
`

const ImageWrapper = styled.div`

	/* Force keen slider resize on mount */

	${props => {
		if (props.mounted) return css`
			position: relative;
		`
	}}
`

const ImgWrapper = styled.div`
	display: block;
`

const Slide = styled.div`	
	display: flex;
	background-color: white;

	${ImageWrapper} {
		display: flex;
    	flex-direction: column;

		${ImgWrapper} {
			flex: 1;
			background: red;
			width: 100%;
			position: relative;

			${Img} {
				width: auto;
				height: 100%;
			}
		}

		${Caption} {
			min-height: 27px;
		}
	}

	/* Hover Caption */
	
	${props => { 
		if (props.hoverCaption) return css`
			${Caption} {
				opacity: 1;
				transition: opacity 0.25s ease;
			}
			
			${media.tablet`
				${Caption} {
					opacity: 1;
				}
			`}
		`
	}}

	${props => {
		if (props.hoverCaption && props.hovering) return css`
			${Caption} {
				opacity: 1;
			}
		`
	}}
`

// Hover Cursors

const Hover = styled.div`
	width: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 2;

	${props => {
		if (props.position == 'left') return css`
			left: 0;
			cursor: -webkit-image-set(
				url(${require('../../assets/images/slider-previous.png')}) 1x,
				url(${require('../../assets/images/slider-previous-2x.png')}) 2x
			), auto;
		`

		if (props.position == 'right') return css`
			right: 0;
			cursor: -webkit-image-set(
				url(${require('../../assets/images/slider-next.png')}) 1x,
				url(${require('../../assets/images/slider-next-2x.png')}) 2x
			), auto;
		`
	}}
`

const HoverCursors = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
`

export default Slider
