import { 
    BlockText,
    BlockDoubleImage
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'large_text': BlockText,
        'paragraph_text': BlockText,
        'single_image': BlockDoubleImage,
        'double_image': BlockDoubleImage
    }

    return blocks[layout]
}


