import React from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

const string = 'Neil Hugh Office'

const AnimatedLogo = ({ hovering }) => {
    const animatedString = Array.from(string)

    const letters = animatedString.map((item, i) => {
		const firstLetterOfWord = (i == 0 || animatedString[i - 1] == ' ');

        return (
            <Letter
                className={'letter'}
				variants={animatedLetter(firstLetterOfWord)}
				firstLetterOfWord={firstLetterOfWord}
				dangerouslySetInnerHTML={{__html: item == ' ' ? '&nbsp' : item}}
            />
        )
    })
    
    return (
        <AnimatedText
			variants={animatedText}
			initial={'static'}
			animate={hovering ? 'animate' : 'static'}
        >
            {letters}
        </AnimatedText>
    )	
}

// Animated Text

const animatedText = {
	animate: {
		transition: {
			duration: 0.35, 
			ease: 'easeInOut',
		}
	}
}

const AnimatedText = styled(motion.div)`
    display: flex;
    justify-content: center;
	overflow: hidden;
`

const animatedLetter = (firstLetterOfWord) => {
	return {
		static: {
			width: firstLetterOfWord ? 'auto' : '0',
			opacity: firstLetterOfWord ? 1 : 0,
			transition: {
				duration: 0, 
				// duration: 0.65, 
				// ease: 'easeInOut',
				// opacity: {
				// 	duration: 0.45, 
				// 	ease: 'easeInOut',
				// }
			}
		},
		animate: {
			width: 'auto',
			opacity: 1,
			transition: {
				duration: 0, 
				// duration: 0.8, 
				// ease: 'easeOut',
				// opacity: {
				// 	duration: 0.6, 
				// 	ease: 'easeIn',
				// 	delay: 0.5
				// }
			}
		},
	}
}

const Letter = styled(motion.div)``

export default AnimatedLogo