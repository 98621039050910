import React from 'react'
import styled, { css } from 'styled-components'
import { type, colours } from '../../styles/global'

// Field Message

const message = css`
    ${type.body}
    font-weight: 500;
`

// Label 

export const renderLabel = ({ label, validator, displayRequired }) => {
    if (!label) return;

    return (
        <Label
            className={'field-label'}
        >
            {label}

            {displayRequired || validator && (
                <Required>*</Required>
            )}
        </Label>
    )
}

const Required = styled.div``

const Label = styled.div`
    display: flex;
    margin-bottom: 4px;
    ${message};

    ${Required} {
        margin-left: 3px;
        color: ${colours.error}
    }
`

// Error

export const checkFieldError = ({shouldValidate, isValid}, focused) => {
    if (!shouldValidate || isValid) return;
    if (!isValid && focused) return;
    return true
}

export const renderError = ({errorMessage, shouldValidate, isValid}, focused) => {
    if (!shouldValidate || isValid) return;
    if (!isValid && focused) return;
    
    return (
        <Error
            className={'error'}
        >
            {errorMessage}
        </Error>
    )
}

export const Error = styled.div`
    ${type.body};
    color: ${colours.error}; 
    margin-top: 8px;
`


// Shared Styles

export const inputBase = css`
 	height: 64px;
    width: 100%;
    font-weight: 500;

    appearance: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    padding: 0;
`