import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { type, colours, elevation } from '../../styles/global'
import { Slider, Direction } from 'react-player-controls'

import { media } from '../../styles/utils';

const AudioProgress = (props) => {
	const { children, styles, goToPosition } = props;
	const [direction, setDirection] = useState(Direction.HORIZONTAL)
	const [value, setValue] = useState(props.value)
	const [hovering, setHovering] = useState(false)

	useEffect(() => {
		!hovering && setValue(props.value)
	}, [props.value])

	return (
		<Wrapper
			className={'progress-bar'}
			styles={styles}
			onChange={newValue => setValue(newValue)}
			onChangeStart={startValue => {
				setValue(startValue)
				setHovering(true)
			}}
			onChangeEnd={endValue => {
				setValue(endValue)
				goToPosition(endValue)
				setHovering(false)
			}}
		>
			<BarWrapper>
				<Bar
					direction={direction}
					value={1}
				/>

				{/* 
				<Bar
					direction={direction}
					value={value}
				/>

				<Bar
					direction={direction}
					value={lastIntent}
					intent
				/> 
				*/}
				
				<Handle
					direction={direction}
					value={value}
				/>
			</BarWrapper>
		</Wrapper>
	)
}

// Layout

const Wrapper = styled(Slider)`
	height: 9px;
	padding-right: 15px;
	padding-left: 5px;

	display: flex;
	align-items: center;

	&::before,
	&::after {
		content: '';
		position: absolute;
		transform: translateY(-50%);
		top: 1px;
		width: 2px;
		background-color: black;
		height: 10px;
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}

	/* Styles */

	${props => props.styles}
`

const BarWrapper = styled.div`
	position: relative;
	width: 100%;
`

const Bar = styled.div`
	position: absolute;
	top: calc(50% - 5px);
	left: 0;
	width: ${props => props.value * 100}%;
	height: 2px;
	background-color: black;
	overflow: visible;

	&::before,
	&::after {
		content: '';
		position: absolute;
		transform: translateY(-50%);
		top: 1px;
		width: 5px;
		background-color: black;
		height: 2px;
	}

	&::before {
		left: -5px;
	}

	&::after {
		right: -15px;
		width: 15px;
	}
`

const Handle = styled.div`
	position: absolute;
	top: calc(50% - 5px);
	left: ${props => props.value * 100}%;
	margin-top: -4px;
	margin-left: 0;

	width: 9px;
	height: 9px;
	background: black;
	border-radius: 50%;
	transform: scale(1);
	transition: transform 0.2s;

	&:hover {
		transform: scale(1.3),
	}
`


export default AudioProgress