import React, { useEffect, useContext} from 'react'
import styled, { css } from 'styled-components'
import { useMount } from 'react-use'
import { isEqual } from 'lodash'
import { useForm } from 'react-dynamic-form'

import { resolveField } from './utils'

const Field = (props) =>  {
    const { id, type } = props;
    const FieldLayout = resolveField(type);
    const { formContext } = useForm();

    const onChange = (value) => {
        const { validator, onChangeValue } = props;
        
        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    return (
        <Wrapper
            id={id}
            className={[id, 'dynamic-field']}
            hidden={props.hidden}
        >
            {FieldLayout && (
                <FieldLayout 
                    {...props}
                    onChange={onChange}
                    placeholder={props.placeholder || props.label}
                />
            )}
        </Wrapper>
    )
}

export const Wrapper = styled.div`
    position: relative;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 23px;
    }

    /* Hidden field */

    ${props => {
        if (props.hidden) return css`
            display: none;
        `
    }}
`

export default Field;