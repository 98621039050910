import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, textHover, type } from '../../../styles/global'

const BlockText = (props) => {
	const { short_text, text, layout } = props;
        
	return (
		<Wrapper
			layout={props.layout}
		>
			<Text
				dangerouslySetInnerHTML={{__html: short_text || text}}  
			/>
		</Wrapper>
	)	
}

const Text = styled.div`
	
`

const Wrapper = styled.div`

	p { 
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	/* Layout */

	${props => {
		if (props.layout == 'large_text') return css`
			max-width: 780px;
			text-align: center;

			${media.phone`
				&, p, * {
					font-size: 24px;
				}
			`}
		`
	}}

	${props => {
		if (props.layout == 'paragraph_text') return css`
			max-width: 665px;

			&, p, * {
				${type.bodySmall};
			}
		`
	}}
`

BlockText.wrapper = css`
	display: flex;
	flex-direction: column;
	align-items: center;

`

export default BlockText
