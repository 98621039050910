import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { type, colours, elevation } from '../../styles/global'
import OutsideClickHandler from 'react-outside-click-handler';
import FullHeight from 'react-div-100vh'

import { media } from '../../styles/utils';

const Drawer = (props) => {
	const { children, elevation, position, styles, className, showCover, active, ...rest } = props;

	return (
		<>
			<OutsideClickHandler
				onOutsideClick={props.outsideClick}
			>
				<Wrapper
					className={['drawer', className]}
					elevation={elevation}
					position={position}
					styles={styles}
					active={active}
					{...rest}
				>
					{children}
				</Wrapper>
			</OutsideClickHandler>

			{showCover && (
				<Cover
					active={active}
				/>
			)}
		</>
	)
}

// Layout

const Wrapper = styled(FullHeight)`
	position: fixed;
	padding: 32px 40px;
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-shadow: 0 4px 20px 0 rgba(0,0,0,0.05);

	${media.phone`
		padding: 18px 20px;
	`}

	/* Position */

	${props => props.position && positions[props.position]}

	/* Styles */

	${props => props.styles}
`

const positionBase = css`
	z-index: 4;
	top: 0;
	bottom: 0;
	width: 510px;
	background: white;
	transition: transform 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);

	${media.phone`
		width: 78vw;
	`}

	${props => {
		if (props.active) return css`
			transition: transform 1s cubic-bezier(0.32, 0.24, 0.15, 1);
			transform: translateX(0);
		`
	}}
`

const positions = {
	left: css`
		left: 0;
		transform: translateX(-100%);
		${positionBase};
	`,
	right: css`
		right: 0;
		transform: translateX(100%);
		${positionBase};

	`
}

// Cover

const Cover = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 3;
	background-color: ${colours.cyclus};
	opacity: 0;
	transition: opacity 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);
	pointer-events: none;

	${props => {
		if (props.active) return css`
			transition: opacity 1s cubic-bezier(0.32, 0.24, 0.15, 1);
			opacity: 0.5;
			pointer-events: all;
		`
	}}
`


Drawer.defaultProps = {
	outsideClick: () => {return}
}

export default Drawer