import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { type, bgImage } from '../../../styles/global'

const BlockDoubleImage = (props) => {
	let images = props.images || [{
		...props?.image,
		...props,
	}]

	console.log(props)

	const renderImages = () => {
		if (!images) return;

		return images.map((item, i) => {
			if (!item?.image) {
				item.image = item
			}
			
			return (
				<ImageWrapper 
					key={i}
					doubleImage={props.images?.length > 1}
					portrait={item.height > item.width}
					reducedSize={props.display_reduced}
				>
					<Image
						key={item.image?.sizes?.medium2}
						src={item.image?.sizes?.medium2}
					>
						{(src, loading) => {
							return (
								<img 
									src={src} 
									alt={item?.image?.alt}
									style={{opacity: loading ? 0 : 1}}  
								/>
							)
						}}
					</Image> 

					{item.use_caption && (
						<Subheading
							dangerouslySetInnerHTML={{__html: item.caption}}
						/>
					)}	
				</ImageWrapper>
			)
		})
	}
        
	return (
		<Wrapper>
			{renderImages()}
		</Wrapper>
	)
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	${media.phone`
		flex-direction: column;
	`}
`

// Image Wrapper

const ImageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	${media.phone`
		&:not(:last-child) {
			margin-bottom: 40px;
		}
	`}

	img {
		width: 100%;
		height: 100%;
	}

	${Subheading} {
		margin-top: 14px;

		&, p {
			${type.caption}
			
			p:last-child {
				margin-bottom: 0;
			}
		}

		${media.phone`
			margin-top: 8px;
		`}
	}

	${props => {
		if (props.doubleImage) return css`
			flex: 0 1 calc(50% - 20px);
			flex-basis: calc(50% -20px);
		`
	}}

	${props => {
		if (props.reducedSize) return css`
			height: 61vh;
			max-width: calc(100vw - 80px);
			object-fit: contain;

			${media.phone`
				object-fit: cover;
				width: 100%;
				height: 100%;
				max-width: calc(100vw - 40px);
				/* height: 366px; */
			`}

			img {
				object-fit: contain;

				${media.phone`
					object-fit: cover;
				`}
			}
		`
	}}
`

BlockDoubleImage.wrapper = css`
	max-width: 44.7vw;

	${media.phone`
		max-width: calc(100vw - 40px);
	`} 

`

export default BlockDoubleImage
