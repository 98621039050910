import { forEach } from 'lodash'

import { 
    FieldInput,
} from './templates'

export const resolveField = (type) => {
    let field;

    // Map layout strings to fields

    const fields = {
        'text': FieldInput, 
        'number': FieldInput, 
        'email': FieldInput, 
        'tel': FieldInput, 
    }
    
    return fields[type]
}